import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { PageContainer, StyledLink } from "../verification/styled";
import { BoldThemedText, RedText, ThemedText } from "../../common/Text";
import Grid from "@mui/material/Grid";
import StyledButton from "../../common/Button";
import ReactCodeInput from "react-code-input";

const Verification = ({
  value,
  onChange,
  onResendPress,
  onSubmit,
  isCodeWrong,
}) => {
  const [counts, setCounts] = useState(20);

  useEffect(() => {
    const countdown = setInterval(() => {
      if (counts >= 1) {
        setCounts(counts - 1);
      }
    }, 1000);

    return () => clearInterval(countdown);
  });

  return (
    <PageContainer>
      <BoldThemedText fontSize="1.25rem">輸入驗證碼</BoldThemedText>
      <ReactCodeInput
        type="text"
        fields={4}
        onChange={onChange}
        inputStyle={{
          boxShadow: "unset",
          width: "44px",
          height: "44px",
          marginLeft: "5px",
          fontSize: "2.5rem",
          textAlign: "center",
        }}
        value={value}
        inputMode="numeric"
      />
      {isCodeWrong && <RedText>驗証碼錯誤</RedText>}
      <StyledButton onClick={onSubmit}>立即驗証</StyledButton>
      <Grid container justifyContent="center">
        <Grid item>
          <ThemedText>沒有收到？</ThemedText>
        </Grid>
        <Grid item>
          <StyledLink
            component="button"
            disabled={counts === 0 ? false : true}
            onClick={onResendPress}
          >
            再發一次({counts})
          </StyledLink>
        </Grid>
      </Grid>
    </PageContainer>
  );
};

export default Verification;

Verification.propTypes = {
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  onResendPress: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};
