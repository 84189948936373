import Container from "@mui/material/Container";
import { styled } from "@mui/material/styles";

export const PageContainer = styled(Container)`
  margin-top: 68px;
  display: flex;
  flex-direction: column;
  align-items: center;

  & > *,
  & > button {
    margin-bottom: 84px;
  }
`;
