import React, { useEffect } from "react";
import Loading from "../components/common/Loading";
import { getURIToStripe } from "../config/api";

const RedirectToPayment = () => {
  useEffect(() => {
    getURIToStripe();
  }, []);

  return <Loading message={"正轉至結帳頁面"} />;
};

export default RedirectToPayment;
