import Box from "@mui/material/Box";
import { BoldThemedText } from "../components/common/Text";
import Button from "../components/common/Button";
import { redirectToCustomerPortal } from "../config/api";

const PaymentSuccess = () => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        minHeight: "100vh",
        maxWidth: "50%",
        margin: "auto",
      }}
    >
      <BoldThemedText fontSize="20px">你的珀尚會帳戶已建立，請在珀尚會APP內登入後使用。謝謝你對珀尚會的支持。</BoldThemedText>
    </Box>
  );
};

export default PaymentSuccess;
