import React from "react";
import { useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { resetPassword } from "../config/api";
import Alert from '@mui/material/Alert';

const ResetPasswordPage = () => {
  const { token } = useParams();
  const [warning, setWarning] = useState("");
  const [password, setPassword] = useState();
  const [message, setMessage] = useState("");
  const [confirmPassword, setConfirmPassword] = useState();

  const handleSubmit = async () => {
    setWarning('');
    if (!checkEqualValue())
      return setWarning("新密碼與確認密碼不相同");

    const success = await resetPassword(token, password);
    if (success) setMessage('密碼已更新!');
  };

  const checkEqualValue = () => {
    return (password.length === confirmPassword.length) && password === confirmPassword;
  }

  return (
    <Container sx={{
      "height": "100vh",
      "display": "flex",
      "flexDirection": "column",
      'justifyContent': "center",
      'alignItems': "center",
      '& > :not(style)': { m: 1, width: '25ch' },
    }} >
      <Box sx={{ padding: 1 }}>
        {warning && <Alert severity="error" sx={{ "display": "block" }}>{warning}</Alert>}
        {message && <Alert severity="success">{message}</Alert>}
      </Box>
      {!message &&
        <Box component="form" sx={{ padding: 3 }}>
          <TextField sx={{ width: 250 }} label="新密碼" variant="standard" onChange={(e) => setPassword(e.target.value)} type="password" />
          <TextField sx={{ width: 250 }} label="確認密碼" variant="standard" onChange={(e) => setConfirmPassword(e.target.value)} type="password" />
        </Box>}
      {!message &&
        <Box sx={{ display: "flex" }}>
          <Button sx={{ flexGrow: 1 }} variant="contained" onClick={handleSubmit}>提交</Button>
        </Box>
      }
    </Container >
  );
};

export default ResetPasswordPage;
