import { verificationMethod, temUserToken } from "./constant";
const basePath = process.env.REACT_APP_API_BASE;

export const createUser = async (data) => {
  const res = await fetch(`${basePath}/auth/signup`, {
    method: "POST",
    body: JSON.stringify(data),
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
    },
  });

  const json = await res.json();
  await localStorage.setItem(temUserToken, json.token);
  return json;
};

export const chooseVerificationMethod = async (method) => {
  await localStorage.setItem(verificationMethod, method);
  const token = await localStorage.getItem(temUserToken);
  const res = await fetch(`${basePath}/auth/verification-choice`, {
    method: "POST",
    body: JSON.stringify({ method }),
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    mode: "cors",
  });

  return res;
};

export const verify = async (code) => {
  const token = await localStorage.getItem(temUserToken);
  const res = await fetch(`${basePath}/auth/verification`, {
    method: "POST",
    body: JSON.stringify({ code: String(code) }),
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    mode: "cors",
  });

  return res;
};

export const getURIToStripe = async () => {
  try {
    const token = await localStorage.getItem(temUserToken);
    const res = await fetch(`${basePath}/payment/payment-url`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      mode: "cors",
    });
    const json = await res.json();

    return (window.location.href = json.redirectURL);
  } catch (e) {
    console.log(e);
  }
};

export const resetPassword = async (token, password) => {
  try {
    const res = await fetch(`${basePath}/user/reset-password`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ "password": password }),
      mode: "cors",
    });
    if (res) return true;

  } catch (e) {
    console.log(e);
    return false;
  }
};

export const redirectToCustomerPortal = async () => {
  const token = await localStorage.getItem(temUserToken);
  try {
    const res = await fetch(`${basePath}/payment/customer-portal`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      mode: "cors",
    });

    const json = await res.json();

    return (window.location.href = json.redirectURL);

  } catch (e) {
    console.log(e);
    return false;
  }
};