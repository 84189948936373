import Box from "@mui/material/Box";
import { BoldThemedText, ThemedText } from "../components/common/Text";
import Button from "../components/common/Button";

const PaymentSuccess = () => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        minHeight: "100vh",
        maxWidth: "50%",
        margin: "auto",
      }}
    >
      <BoldThemedText fontSize="20px">付款失敗</BoldThemedText>
      <ThemedText>請稍後再試</ThemedText>
      <ThemedText>或與你的銀行聯絡</ThemedText>
    </Box>
  );
};

export default PaymentSuccess;
