import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

const StyledSelect = ({ options = [], ...rest }) => {
  return (
    <Select {...rest}>
      {options.map((option, key) => (
        <MenuItem value={option.value} key={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </Select>
  );
};

export default StyledSelect;
