import TextField from "@mui/material/TextField";
import Label from "@mui/material/InputLabel";
import PropTypes from "prop-types";
import Select from "../../common/Select";
import DatePicker from "../../common/DatePicker";
import {
  Button,
  PageContainer,
  Name,
  Mobile,
  FlexRow,
  FlexColumn,
  StyledRedText,
} from "./styled";
import code from "../../../config/countriyCode";
import CircularProgress from '@mui/material/CircularProgress';

const Registration = ({ formik, submitError, isLoading }) => {
  const commonField = [

    { label: "電子郵件", required: true, valueKey: "email", type: "text" },
    {
      label: "確認電子郵件",
      required: true,
      valueKey: "confirm_email",
      type: "text",
    },
    {
      label: "密碼",
      required: true,
      valueKey: "password",
      additionLabel: "(為8個英文及數字組成)",
      type: "password",
    },
    {
      label: "確認密碼",
      required: true,
      valueKey: "confirm_password",
      validationRule: "(為8個英文及數字組成)",
      type: "password",
    },
    {
      label: "兌換碼 / 推薦碼",
      required: false,
      valueKey: "used_referral_code",
      validationRule: null,
      type: "text",
    },
  ];

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <PageContainer>
          <StyledRedText>*為必須輸入</StyledRedText>
          <Name>
            <div>
              <FlexRow>
                <Label>英文姓氏</Label>
                <StyledRedText>*</StyledRedText>
              </FlexRow>
              <TextField
                id="lname"
                name="lname"
                value={formik.values.lname}
                onChange={formik.handleChange}
                error={formik.touched.lname && Boolean(formik.errors.lname)}
                helperText={formik.touched.lname && formik.errors.lname}
                onBlur={formik.handleBlur}
              />
            </div>
            <FlexColumn>
              <FlexRow>
                <Label>英文名字</Label>
                <StyledRedText>*（姓名須與付款卡所列相同）</StyledRedText>
              </FlexRow>
              <TextField
                id="fname"
                name="fname"
                value={formik.values.fname}
                onChange={formik.handleChange}
                error={formik.touched.fname && Boolean(formik.errors.fname)}
                helperText={formik.touched.fname && formik.errors.fname}
                onBlur={formik.handleBlur}
              />
            </FlexColumn>
          </Name>
          <Mobile>
            <div>
              <FlexRow>
                <Label>手提電話</Label>
                <StyledRedText>*</StyledRedText>
              </FlexRow>
              <Select
                id="mobile_prefix"
                name="mobile_prefix"
                value={formik.values.mobile_prefix}
                onChange={formik.handleChange}
                error={
                  formik.touched.mobile_prefix &&
                  Boolean(formik.errors.mobile_prefix)
                }
                options={code}
              />
            </div>
            <div>
              <TextField
                id="mobile"
                name="mobile"
                inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                value={formik.values.mobile}
                onChange={formik.handleChange}
                error={formik.touched.mobile && Boolean(formik.errors.mobile)}
                helperText={formik.touched.mobile && formik.errors.mobile}
              />
            </div>
          </Mobile>
          <div key="生日日期 (DD-MMM-YYY)">
            <FlexRow>
              <Label>生日日期 (DD-MMM-YYY)</Label>{" "}
            </FlexRow>
            <DatePicker
              views={['day', 'month', 'year']}
              openTo="day"
              inputFormat="DD-MMM-YYYY"
              id="birthday"
              name="birthday"
              value={formik.values["birthday"]}
              onChange={(value) =>
                formik.setFieldValue("birthday", value)
              }
              onBlur={formik.handleBlur}
            /> 
          </div>
          {commonField.map((field, key) => {
            return (
              <div key={field.label}>
                <FlexRow>
                  {/* render label */}
                  <Label>{field.label}</Label>{" "}
                  {field.required && <StyledRedText>*</StyledRedText>}
                  {field.additionLabel && (
                    <StyledRedText>{field.additionLabel}</StyledRedText>
                  )}
                </FlexRow>
                {/* render type */}
                {field.type === "datePicker" && (
                  <DatePicker
                  views={['day', 'month', 'year']}
                    openTo="day"
                    inputFormat="DD-MMM-yyyy"
                    minDate={new Date('1900-01-01')}
                    id={field.valueKey}
                    name={field.valueKey}
                    value={formik.values[field.valueKey]}
                    onChange={(value) =>
                      formik.setFieldValue(field.valueKey, value)
                    }
                    onBlur={formik.handleBlur}
                    disableMaskedInput={true}
                    disabled={true}
                  />
                )}
                {(field.type === "text" ||
                  field.type === "number" ||
                  field.type === "password") && (
                    <TextField
                      id={field.valueKey}
                      name={field.valueKey}
                      inputProps={
                        field.type === "number"
                          ? { inputMode: "numeric", pattern: "[0-9]*" }
                          : {}
                      }
                      value={formik.values[field.valueKey]}
                      onChange={formik.handleChange}
                      error={
                        formik.touched[field.valueKey] &&
                        Boolean(formik.errors[field.valueKey])
                      }
                      helperText={
                        formik.touched[field.valueKey] &&
                        formik.errors[field.valueKey]
                      }
                      type={field.type === "password" ? "password" : "text"}
                      onBlur={formik.handleBlur}
                    />
                  )}
              </div>
            );
          })}
          <Button type="submit" style={{ height: "60px" }}>{isLoading ? <CircularProgress style={{ maxHeight: "60px" }} /> : `立即註冊`}</Button>
          <div>{submitError}</div>
        </PageContainer>
      </form>
    </>
  );
};

export default Registration;

Registration.propTypes = {
  formik: PropTypes.object.isRequired,
};
