import { styled } from "@mui/material/styles";
import Container from "@mui/material/Container";
import StyledButton from "../../common/Button";
import { RedText } from "../../common/Text";

export const PageContainer = styled(Container)`
  padding-top: 68px;
  padding-bottom: 24px;

  & > div:not(:first-child) {
    margin-top: 24px;
  }

  & > div,
  & > div > div {
    width: 100%;
  }

  input {
    width: 100%;
  }
`;

export const FlexRow = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const FlexColumn = styled("div")`
  display: flex;
  flex-direction: column;
`;

export const Name = styled(FlexRow)`
  & > div:nth-child(1) {
    flex: 1;
  }

  & > div:nth-child(2) {
    flex: 3;
    margin-left: 20px;
  }
`;

export const Mobile = styled(FlexRow)`
  align-items: flex-end;

  & > div:nth-child(1) {
    flex: 2;
  }

  & > div:nth-child(2) {
    flex: 5;
    margin-left: 20px;
  }

  & > div > div {
    width: 100%;
  }
`;

export const Button = styled(StyledButton)({
  marginTop: "55px",
  width: "100%",
});

export const StyledRedText = styled(RedText)`
  font-size: 0.875rem;
`;
