import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Verification from "../components/page/verification";
import { verify, chooseVerificationMethod } from "../config/api";
import { verificationMethod } from "../config/constant";

const VerificationPage = () => {
  const navigate = useNavigate();
  const [code, setCode] = useState();
  const [isCodeWrong, setIsCodeWrong] = useState(false);

  const onCodeChange = (val) => {
    setCode(val);
  };

  const onSubmit = async () => {
    const res = await verify(code);
    const json = await res.json();

    if (json.verified) {
      navigate("/redirect-to-payment");
    } else {
      setIsCodeWrong(true);
    }
  };

  const onResendPress = async () => {
    const method = localStorage.getItem(verificationMethod);
    await chooseVerificationMethod(method);
  };

  return (
    <Verification
      value={code}
      onChange={onCodeChange}
      onSubmit={onSubmit}
      onResendPress={onResendPress}
      isCodeWrong={isCodeWrong}
    />
  );
};

export default VerificationPage;
