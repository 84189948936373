import PropTypes from "prop-types";
import { PageContainer, HeaderText } from "./styled";
import { ReactComponent as VerificationAmigo } from "../../assets/Verified-amico.svg";
import Button from "../../common/Button";
import { BoldThemedText } from "../../common/Text";

const Verification = ({ phonVerification, emailVerfication }) => {
  return (
    <>
      <PageContainer>
        <BoldThemedText fontSize="1.25rem">選擇你的驗証方式</BoldThemedText>
        <VerificationAmigo />
        <Button onClick={phonVerification}>手機驗證 （暫只適用於香港電話號碼）</Button>
        <Button onClick={emailVerfication}>電郵驗證</Button>
      </PageContainer>
    </>
  );
};

export default Verification;

Verification.propTypes = {
  phonVerification: PropTypes.func.isRequired,
  emailVerfication: PropTypes.func.isRequired,
};
