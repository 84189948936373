import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";

export const RedText = styled(Typography)(({ theme }) => ({
  color: theme.palette.error.main,
}));

export const ThemedText = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
}));

export const BoldThemedText = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: 900,
}));
