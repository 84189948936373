import Container from "@mui/material/Container";
import { styled } from "@mui/material/styles";
import Link from "@mui/material/Link";

export const PageContainer = styled(Container)`
  margin-top: 68px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & > *,
  & > button,
  & > p {
    margin-bottom: 84px;
  }
`;

export const StyledLink = styled(Link)(({ theme, disabled }) => ({
  color: disabled ? theme.palette.primary.light : theme.palette.primary.main,
}));
