import React from "react";
import { useNavigate } from "react-router-dom";
import Verification from "../components/page/verification-choice";
import { chooseVerificationMethod } from "../config/api";

const VerificationPage = () => {
  const navigate = useNavigate();

  const phoneVerification = () => {
    chooseVerificationMethod("phone");
    navigate("/verification");
  };
  const emailVerification = () => {
    chooseVerificationMethod("email");
    navigate("/verification");
  };

  return (
    <>
      <Verification
        phonVerification={phoneVerification}
        emailVerfication={emailVerification}
      />
    </>
  );
};

export default VerificationPage;
