import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Registration from "./pages/registration";
import VerificationChoicePage from "./pages/verification-choice";
import Verify from "./pages/verification";
import RedirectToPayment from "./pages/redirect-to-payment";
import PaymentCancelled from "./pages/payment-cancel";
import PaymentSuccess from "./pages/payment-succeed";
import ResetPassword from "./pages/reset-password";
import Privacy from "./pages/privacy";
// import CustomerPortal from "./pages/customer-portal";

const theme = createTheme({
  palette: {
    primary: {
      light: "#d0cbe5",
      main: "#4123A8",
    },
  },
  components: {
    MuiButtonBase: {
      root: {
        MuiButton: {
          root: {
            margin: "unset",
          },
        },
      },
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router basename="/towa">
        <Routes>
          <Route path={`/registration`} element={<Registration />} />
          <Route
            path={`/verification-choice`}
            element={<VerificationChoicePage />}
          />
          <Route path={`/verification`} element={<Verify />} />
          <Route
            path={`/redirect-to-payment`}
            element={<RedirectToPayment />}
          />
          <Route path={`/payment-cancel`} element={<PaymentCancelled />} />
          <Route path={`/payment-succeed`} element={<PaymentSuccess />} />
          <Route path={`/reset-password/:token`} element={<ResetPassword />} />
          <Route path={`/privacy/info`} element={<Privacy />} />
          {/* <Route path={`/custom-portal`} element={<CustomerPortal />} /> */}
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
