import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import Registration from "../components/page/registration";
import { createUser } from "../config/api";


const SignupSchema = yup.object().shape({
  email: yup.string().email("請輸正確的入電郵").required("請輸入電郵"),
  confirm_email: yup
    .string()
    .email("請再次輸入電郵")
    .oneOf([yup.ref("email")], "電郵不相符")
    .required("請再次輸入電郵"),
  mobile: yup.string("請輸入正確的電話號碼").required("請輸入電話號碼"),
  mobile_prefix: yup.string("請輸入正確的電話號碼").required("請選擇"),
  //birthday: yup.date().required("請輸入生日日期"),
  password: yup
    .string()
    .required("請輸入密碼")
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/,
      "密碼必須由8個英文及數字組成"
    ),
  confirm_password: yup
    .string()
    .oneOf([yup.ref("password")], "密碼不相符")
    .required("請再次輸入密碼"),
  fname: yup.string().required("請輸入名稱"),
  lname: yup.string().required("請輸入姓氏"),
});

const RegistrationPage = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [submitError, setSubmitError] = useState();
  const formik = useFormik({
    initialValues: {
      email: "",
      confirm_email: "",
      password: "",
      confirm_password: "",
      mobile: "",
      mobile_prefix: "+852",
      birthday: null,
      fname: "",
      lname: "",
      referral_code: "",
    },
    validationSchema: SignupSchema,
    onSubmit: async (values) => {
      setIsLoading(true);
      const json = await createUser(values);
      if (json.code === 201) navigate("/verification-choice");
      else {
        setIsLoading(false)
        setSubmitError(json.msg);
      }
    },
  });
  return (
    <>
      <Registration formik={formik} submitError={submitError} isLoading={isLoading} />
    </>
  );
};

export default RegistrationPage;
