//import AdapterDateFns from "@mui/lab/AdapterDateFns";
//import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
//import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
//import DatePicker from "@mui/lab/DatePicker";
import TextField from "@mui/material/TextField";
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';

const CustomDatePicker = (props) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <MobileDatePicker
        {...props}
        renderInput={(params) => {
          return <TextField {...params} />;
        }}
      />
    </LocalizationProvider>
  );
};

export default CustomDatePicker;
