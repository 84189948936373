import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import { BoldThemedText } from "./Text";

const ThemedButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.light,
  width: "100%",
  padding: "15px 0",
}));

const StyledButton = (props) => {
  return (
    <ThemedButton {...props}>
      {typeof props.children === "string" ? (
        <BoldThemedText>{props.children}</BoldThemedText>
      ) : (
        props.children
      )}
    </ThemedButton>
  );
};

export default StyledButton;
